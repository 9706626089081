// consumer_web_policyConfig_deletePolicyByBusinessType
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：http://yapi.yinzhitravel.com/project/52/interface/api/15244
const consumer_web_policyConfig_deletePolicyByBusinessType = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/deletePolicyByBusinessType',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_policyConfig_deletePolicyByBusinessType;